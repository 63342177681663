import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgIf } from '@angular/common';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { UtilService } from '@shared/services/util.service';
import { QrcodeComponent } from '@owner/modals/qrcode/qrcode.component';
import { environment } from '@env/environment';
import { catchError, of, tap } from 'rxjs';
import Property from '@shared/models/Property';
import { PropertyService } from '@shared/requests/property.service';
import { DeletePropertyComponent } from '@owner/modals/delete-property/delete-property.component';
import { isArray } from 'lodash';

@Component({
  selector: 'app-property-icon',
  standalone: true,
  imports: [
    IonicModule,
    NgIf,
    TranslateModule
  ],
  templateUrl: './property-icon.component.html',
  styleUrl: './property-icon.component.scss'
})
export class PropertyIconComponent {
  @Input() public property = new Property();

  @Input() public home = false;

  @Output() onUpdate = new EventEmitter<unknown>();

  public link = environment.link;

  constructor(
    private util: UtilService,
    private m: ModalController,
    private http: PropertyService,
  ) { }

  public stopRedirect(event: Event): void {
    event.stopPropagation();
  }

  public onCopy(): void {
    this.util.copy(`${this.link}${this.property?.link}`);
  }

  async openQRCodeModal(): Promise<void> {
    const m = await this.m.create({
      component: QrcodeComponent,
      componentProps: { property: this.property },
    });
    return m.present();
  }

  activate() {
    this.property.state = 'active';
    this.edit();
  }

  deactivate() {
    this.openDeleteModal();
  }

  private edit() {
    if (isArray(this.property.vigilancePoints)) {
      this.property.vigilancePoints = this.property?.vigilancePoints?.toString();
    }

    this.http.update(this.property).pipe(
      tap((res) => {
        this.property = res;
      }),
      catchError(() => {
        return of(null);
      })
    ).subscribe(() => {
      this.onUpdate.emit();
    });
  }

  async openDeleteModal(): Promise<void> {
    const m = await this.m.create({
      component: DeletePropertyComponent,
      cssClass: 'modal-DeleteProperty',
      componentProps: {
        id: this.property.id,
        state: this.property.state
      },
    });

    m.onDidDismiss()
      .then(({ data }) => {
        if (data === 'success') {
          this.onUpdate.emit();
          this.property.state = 'archived';
        }
      });

    return m.present();
  }
}
