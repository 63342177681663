import { Component, Input } from '@angular/core';
import { DecimalPipe, NgIf } from '@angular/common';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { UtilService } from '@shared/services/util.service';
import { QrcodeComponent } from '@owner/modals/qrcode/qrcode.component';
import PaymentLink from '@shared/models/PaymentLink';
import { environment } from '@env/environment';
import { catchError, of, tap } from 'rxjs';
import { PaymentLinkService } from '@shared/requests/payment-link.service';

@Component({
  selector: 'app-icon',
  standalone: true,
  imports: [
    DecimalPipe,
    IonicModule,
    NgIf,
    TranslateModule
  ],
  templateUrl: './icon.component.html',
  styleUrl: './icon.component.scss'
})
export class IconComponent {
  @Input() public paymentLink = new PaymentLink();

  public link = environment.link;

  constructor(
    private util: UtilService,
    private m: ModalController,
    private http: PaymentLinkService,
  ) { }

  public stopRedirect(event: Event): void {
    event.stopPropagation();
  }

  public onCopy(): void {
    this.util.copy(`${this.link}payment-link/${this.paymentLink.link}`);
  }

  async openQRCodeModal(): Promise<void> {
    const m = await this.m.create({
      component: QrcodeComponent,
      componentProps: { paymentLink: this.paymentLink },
    });
    return m.present();
  }

  activate() {
    this.paymentLink.status = 'active';
    this.edit();
  }

  deactivate() {
    this.paymentLink.status = 'inactive';
    this.edit();
  }

  private edit() {
    this.http.edit(this.paymentLink.id, this.paymentLink).pipe(
      tap((res) => {
        this.paymentLink = res;
      }),
      catchError(() => {
        return of(null);
      })
    ).subscribe();
  }
}
