import User from '@shared/models/User';

export default class PaymentLink {
  public id!: string;

  public amount!: number;

  public tvaType!: string;

  public tvaValue!: number;

  public ttcAmount!: number;

  public link!: string;

  public description!: string;

  public createdAt!: Date;

  public createdBy!: User;

  public status!: string;

  public name!: string;
}
