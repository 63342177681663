<div class="row" *ngIf="paymentLink.status === 'active'">
    <div class="col-3 ion-text-center">
        <ion-button (click)="stopRedirect($event); onCopy()" class="button__rounded light-blue">
            <i class="fal fa-copy"></i>
        </ion-button>
    </div>
    <div class="col-3 ion-text-center">
        <ion-button href="mailto:?body={{ link + paymentLink.link }}"
                    class="button__rounded light-green" (click)="stopRedirect($event);">
            <i class="fal fa-envelope"></i>
        </ion-button>
    </div>
    <div class="col-3 ion-text-center">
        <ion-button (click)="stopRedirect($event); openQRCodeModal()" class="button__rounded light-gray">
            <i class="fal fa-qrcode"></i>
        </ion-button>
    </div>
    <div class="col-3 ion-text-center">
        <ion-button (click)="stopRedirect($event); deactivate()" class="button__rounded light-red">
            <i class="fal fa-pause-circle"></i>
        </ion-button>
    </div>
</div>

<div class="row justify-content-end" *ngIf="paymentLink.status === 'inactive'">
    <div class="col-3">
        <ion-button (click)="stopRedirect($event); activate()" class="button__rounded light-green">
            <i class="fal fa-power-off"></i>
        </ion-button>
    </div>
</div>
