import { Component, Input } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { ToastService } from '@shared/services/toast.service';
import { PropertyService } from '@shared/requests/property.service';
import { catchError, tap } from 'rxjs';

@Component({
  selector: 'app-delete-property',
  templateUrl: './delete-property.component.html',
  styleUrls: ['./delete-property.component.scss'],
})
export class DeletePropertyComponent {
  @Input() id!: number;

  @Input() state!: string;

  constructor(
    private m: ModalController,
    private http: PropertyService,
    private router: NavController,
    private toast: ToastService
  ) { }

  public onClose(): void {
    this.m.dismiss();
  }

  public onSubmit(): void {
    this.http
      .delete(this.id)
      .pipe(
        tap(() => {
          this.router.navigateRoot(['/owner', 'properties']);
          this.m.dismiss('success');
        }),
        catchError(() => {
          this.toast.toastError('DELETE_ERROR');
          return [];
        })
      )
      .subscribe();
  }
}
