import { Injectable } from '@angular/core';
import { AbstractRequest } from '@shared/requests/AbstractRequest';
import { Observable } from 'rxjs';
import PaymentLink from '@shared/models/PaymentLink';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PaymentLinkService extends AbstractRequest {
  public fetchAll(page?: number, items?: number, sortBy?: string | null, sortDirection?: string | null, status?: string | null, search?: string | null): Observable<any> {
    const names = ['page', 'items', 'sortBy', 'sortDirection', 'status', 'search'];
    const args = this.formatForStateArgs(names, arguments);

    const params = new HttpParams({
      fromObject: args,
    });

    return this.http
      .get<any>(`${this.endpoint}/payment-links`, {
        params,
      });
  }

  public fetch(id: string): Observable<PaymentLink> {
    return this.http.get<PaymentLink>(`${this.endpoint}/payment-links/${id}`);
  }

  public create(paymentLink: PaymentLink): Observable<PaymentLink> {
    return this.http.post<PaymentLink>(`${this.endpoint}/payment-links`, paymentLink);
  }

  public edit(id: string, paymentLink: PaymentLink): Observable<PaymentLink> {
    return this.http.patch<PaymentLink>(`${this.endpoint}/payment-links/${id}`, paymentLink);
  }

  public delete(id: string): Observable<PaymentLink> {
    return this.http.delete<PaymentLink>(`${this.endpoint}/payment-links/${id}`);
  }

  public qrcode(id: string): Observable<any> {
    return this.http.get<any>(`${this.endpoint}/payment-links/${id}/qrcode`);
  }
}
