import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { catchError, of, tap } from 'rxjs';
import { PropertyService } from '@shared/requests/property.service';
import Property from '@shared/models/Property';
import PaymentLink from '@shared/models/PaymentLink';
import { PaymentLinkService } from '@shared/requests/payment-link.service';

@Component({
  selector: 'app-qrcode',
  templateUrl: './qrcode.component.html',
  styleUrls: ['./qrcode.component.scss'],
})
export class QrcodeComponent implements OnInit {
  @Input() property!: Property;

  @Input() paymentLink!: PaymentLink;

  public data!: string;

  public loading = true;

  constructor(
    private httpProperty: PropertyService,
    private httpPaymentLink: PaymentLinkService,
    private m: ModalController
  ) {
  }

  ngOnInit(): void {
    this.loading = true;

    if (this.property !== undefined) {
      this.httpProperty
        .qrcode(this.property.id)
        .pipe(
          tap((data) => {
            this.data = data.base64;
            this.loading = false;
          }),
          catchError(() => {
            this.loading = false;
            return of(null);
          })
        )
        .subscribe();
    }

    if (this.paymentLink !== undefined) {
      this.httpPaymentLink
        .qrcode(this.paymentLink.id)
        .pipe(
          tap((data) => {
            this.data = data.base64;
            this.loading = false;
          }),
          catchError(() => {
            this.loading = false;
            return of(null);
          })
        )
        .subscribe();
    }
  }

  public onClose(): void {
    this.m.dismiss().then();
  }
}
