<ion-content class="delete">
  <ion-button (click)="onClose()" class="close" color="primary">
    <i class="far fa-times"></i>
  </ion-button>
  <h3>
    <ng-container *ngIf="state ==='active'">
      {{ 'globals.deleteAsset' | translate }}
    </ng-container>
    <ng-container *ngIf="state ==='archived'">
      {{ 'globals.reactivateAsset' | translate }}
    </ng-container>

    <br />
  </h3>

  <div class="item">
    <ng-container *ngIf="state !=='archived'">
      {{ 'pages.createAsset.deleteAssetMessage' | translate }}
    </ng-container>
    <ng-container *ngIf="state ==='archived'">
      {{ 'pages.createAsset.activateAssetMessage' | translate }}
    </ng-container>
  </div>
  <div class="action">
    <ion-button color="danger" (click)="onClose()" class="new"> {{
      'actions.cancel' | translate }} </ion-button>
    <ion-button (click)="onSubmit()" class="new"> {{ 'actions.confirm' |
      translate }} </ion-button>
  </div>
</ion-content>
